<template>
	<div>
		<div class="box">

			<FormQualification :qualif_type="qualif_type"/>
		</div>
	</div>
</template>

<script type="text/javascript">

	export default {
		name: 'SearchQualification',
		mixins: [],
		props: ['qualif_type'],
		data () {
			return {
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {

			},
		},

		components: {
			FormQualification: () => import('@/components/Qualification/FormQualification'),
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
		}
	}

</script>